<template>
  <div class="appWrapper"  :class="{ 'ar-lang': isArabicLang }">
    <div class="container">
      <div class="home-button">
        <router-link to="/">
          <img
            src="@/assets/img/home.png"
            alt="Home"
            class="top-left-image"
            :style="{ filter: activeOptionId === 1 ? 'invert(1)' : '' }"
            @click="addBtntagging('Home', currentBaseUrl, '', '')"
          />
        </router-link>
      </div>
      <div class="areaheaderName" v-if="!interiorMaster">
        <h1 v-if="isImagesLoaded" class="larger-font">
          {{ isArabicLang  ? selectedVehicleAr : selectedVehicleEn }}
        </h1>
      </div>

      <ModalForm
        :is-book-now-modal-visible="isBookNowModalVisible"
        @close="isBookNowModalVisible = false"
        :model-list="vehicleModelList"
      />
      <div
        class="mainOptionWrapper"
        ref="mainOptionRef"
        id="mainOptionWrapper"
        v-if="exOptions"
      >
        <div
       
          class="mainOption"
          v-for="(category, index) in this.selectedGradeCategories"
          :key="index"
          @click="getCategory(category, index)"
          :class="{ activeMain: index === activeOptionId }"
        >
          <div class="option">
            <!-- <div class="labelIn">{{ category }}</div> -->
            <div class="labelIn">{{ isArabicLang ? arabicCategories[index] : category }}</div>

            <span></span>
          </div>
        </div>
      </div>
      <div>
        <div class="landingInner" v-if="this.vehicle">
          <div class="navWrapper fade-in-top" v-if="!isLoading">
            <!-- Categories ( Exterior & Interior Area ) -->
            <div
              class="mainOptionWrap"
              ref="mainOptionRef"
              id="mainOptionWrapper"
              v-if="exOptions"
            >
              <div
                class="mainOption"
                v-for="(category, index) in this.selectedGradeCategories"
                :key="index"
              >
                <div
                  class="option"
                  @click="getCategory(category, index)"
                  :class="{ activeMain: index === activeOptionId }"
                >
                  <div class="labelIn">{{ category }}</div>
                  <span></span>
                </div>
              </div>
            </div>
            <!-- // Categories ( Exterior & Interior Area ) -->
          </div>
          <!-- navWrapper end -->

          <div ref="InExWrapper" class="InExWrapper">
            <div
              class="Ex"
              v-if="!interiorMaster && threeSixtyImagePath"
              ref="Exee"
              :class="hotspotclicked ? 'zoomed' : ''"
            >
              <div v-if="this.threesixtyStatus">
                <vue-three-sixty
                  ref="_360_"
                  :key="this.dataChanged"
                  :amount="this.imageCount"
                  :imagePaths="this.threeSixtyImagePath"
                  :disableZoom="true"
                  
                 

                  stopAtEdges
                  :scrollImage="true"
                  :hotspots="this.exteriorHotspots"
                  :setSpotCurrentImage="this.setSpotCurrentImage"
                  @getHotWidget="fetchExteriorHot($event)"
                  :openHotspotFormForFeatureId="
                    this.openHotSpotFormImgFeatureId
                  "
                  @imagesLoaded="imageUpdated"
                />
              </div>

              <div v-if="this.vehicleFoundStatus" class="exNotFound">
                Exterior image not found!
              </div>

              <div class="colorsTray" v-if="imageUpdated">
                <ul id="colorPicker">
                  <li
                    v-for="(imgColour, index) in this.imageExteriorColourList"
                    :key="index"
                    :class="{ active: index === isActiveColor }"
                    @click="
                      changeExteriorCarColour(imgColour.colour_code, index, imgColour.colour_code_ar)
                    "
                  >

                    <img :src="imgColour.colour.image" />

                  </li>
                </ul>
                <div class="color">
                  <label>
                    {{ this.selectedExteriorColor }}
                  </label>
                </div>
              </div>

              <div class="widgetBottom">

                <div class="gradeSelect">
                  <div class="label">
                    <label for="vehicleModel"  class="widget-label">  {{ isArabicLang  ? "الطرازات" : "Grades" }}</label>
                  </div>
                  <div class="select">
                    <select class="main-select" id="vehicleModel" @change="onVehicleModelChange($event)" :disabled="this.gradeoption">
                      <option v-for="(item, index) in this.vehicleData.grades" :key="index" :value="item.grade"
                       :selected="selectedVehicleGradeName === item.grade">
                        {{ isArabicLang  ? item.grade_ar : item.grade }}

                      </option>
                    </select>
                  </div>
                </div>

                <div>
                  <div class="label">
                    <label class="widget-label">&nbsp;</label>
                  </div>
                  <div class="ctaWrap d-none">
                    <!-- <div class="discoverBtn">
                      <a href="#" @click="showModal">Book a test drive</a>
                    </div> -->
                    <div class="discoverBtn">
                      <a
                        :href="this.selectedCta_1_Link"
                        @click="
                          addBtntagging(
                            selectedCta_1,
                            selectedCta_1_Link,
                            selectedvehicleModel,
                            selectedVehicleId
                          )
                        "
                      >
                        {{ isArabicLang ? 'اكتشف المزايا ' : this.selectedCta_1}}</a>
                    </div>
                    <div class="discoverBtn">
                      <a
                        :href="this.selectedCta_2_Link"
                        @click="
                          addBtntagging(
                            selectedCta_2,
                            selectedCta_2_Link,
                            selectedvehicleModel,
                            selectedVehicleId
                          )
                        "
                      >
                      {{ isArabicLang ? 'تخصيص' : this.selectedCta_2}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader v-if="this.divLoaded" v-show="this.PanIcon" ref="PanIcon" />
          </div>

          <!-- interior start -->
          <div class="interiorMasterWrapper" v-if="interiorMaster">
            <div
              class="colorToggleBtn"
              @click="toggleInteriorColourList"
              ref="colorToggleBtn"
            ></div>
            <div class="colorBoxInterior" v-if="this.InteriorColourListbox">



              <VueSlickCarousel
              :key="carouselKey"
              v-bind="settings"
              class="colorsTray fade-in-bottom aniDelay-2s"
              ref="colorsTray"
              v-if="this.imageInteriorColourList"

            >


             <div


              v-for="(imgColour, index) in this.imageInteriorColourList"
                        :key="index"

                            class="slideItem"
                            :class="{ active: index === isActiveInteriorColor }"
                            @click="
                              changeInteriorCarColour(imgColour.colour_code, index, imgColour.colour_code_ar)
                              "
                          >
                            <img
                              :src="imgColour.colour.image"
                              alt="Color Image"
                            />
                            <!-- <p class="image-text" :id="index">{{updatedColorName(imgColour.colour.name)}}</p> -->
                      </div>

            </VueSlickCarousel>

              <p class="colortrimmed">{{ copyColorTrimmed }}</p>
            </div>

            <div
              class="In"
              v-if="this.vehicleData.grades.length > 0 && this.dataChanged"
            >
              <PanoramaView
                :vehicleModel="this.vehicleData.model"
                :vehicleData="this.interiorData"
                :interiorHotspots="this.interiorHotspots"
                :windowWidth="this.windowWidth"
                :key="this.dataChanged"
                :openHotspotFeatureBox="this.openHotSpotFormImgFeatureId"
                :colorIndex="this.colorIndex"
                :selectedColor="this.selectedInteriorColorParam"
              />
            </div>
            <div v-else class="InteriorNot">Interior image not found!</div>
          </div>
          <!-- interior end -->
        </div>
      </div>
      <div class="overlay" v-if="!isImagesLoaded">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Logo from "../components/logoNissan.vue";
import Loader from "../components/loader.vue";
import CarouselColor from "../components/vueCarousel.vue";
import "vue-360/dist/css/style.css";
import store from "@/store";
import adobeAnalytics from "@/plugin/analytics";
import ModalForm from "../components/modals/bookNow.vue";
import { checkLogin } from "@/components/constants/functions";




import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "LandingView",
  props: {
    action: Boolean,
  },
  components: {
    PanoramaView: () => import("../components/interiorPanorama.vue"),
    Logo,
    Loader,
    CarouselColor,
    ModalForm,


    VueSlickCarousel

  },

  data() {
    return {

      settings: {
        arrows: true,
        mobileFirst: true,
        dots: false,
        slidesToShow: 7,
        rows: 1,
        touchThreshold: 5,
        infinite: false,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,

            }
          }
        ]
      },

      isActiveColor: 0,
      valueDef: 0,
      carouselKey: "",

      isArabicLang: false,

      selectedVehicleEn: "",
      selectedVehicleAr: "",

      selectedGradeCategories: ['ext 1', 'int 2',],
      arabicCategories: ['الشكل الخارجي', 'المقصورة الداخلية'],

      selectedInteriorColorAR: "",
      interiorCategoryItemData: null,


      gradeoption: false,
      PanIcon: false,
      copyColorTrimmed: "",
      InteriorColourListbox: true,
      isImagesLoaded: false,
      currentBaseUrl: window.location.origin,
      colorsInExterior: false,
      selectedvehicleModel: null,
      threesixtyStatus: true,
      selectedExteriorColor: null,
      selectedInteriorColor: null,
      selectedInteriorColorParam: null,

      vehicleFoundStatus: false,
      vehicleModelList: [],
      selectedVehicleGradeData: [],
      selectedVehicleGradeDataFull:[],
      selectedVehicleModelName: null,
      selectedVehicleGradeName: null,
      selectedVehicleId: null,

      selectedCta_1: null,
      selectedCta_1_Link: null,

      selectedCta_1_AR: null,
      selectedCta_1_Link_AR: null,

      selectedCta_2: null,
      selectedCta_2_Link: null,

      selectedCta_2_AR: null,
      selectedCta_2_Link_AR: null,


      choosedVehicle: "",
      sidePanel: false,
      playOnce: false,
      colorName: null,
      exOptions: true,
      gradeIn: false,
      divLoaded: false,
      isLoadingFeature: true,
      isLoadingFeature2: true,
      isLoading: true,
      modelName: null,
      vehicle: {},

      // adobe
      catgoryTypo: "",
      adobeGradecode: null,
      modelCode: "",
      defColor: null,
      categoryExInt: null,

      openHotSpotFormImgFeatureId: 0,

      toggleLeftFeatureBox: true,
      isActiveFeaturebox: true,
      toggleClicked: false,
      toggleRightFeatureBox: true,
      sm_toggleMenu: true,

      imageCount: null,
      threeSixtyImagePath: null,
      threeSixtyFilename: "car_{index}.png",

      widgetActive: false,
      isWidgetHotActive: false,

      activeOptionId: 0,
      gradeWalkId: 0,
      activeGradeId: 0,
      activeFeatureId: 0,
      activeIntId: 1,
      isActiveMain: false,
      mainOptionRef: false,
      windowWidth: null,
      mobMode: false,
      isActiveColor: 1,
      isActiveInteriorColor: 0,

      exteriorHotspots: [
        {
          id: null,
          frame: null,
          heading: null,
          text: null,
          x: null,
          y: null,
          video: null,
          thumbnail: null,
        },
      ],

      interiorHotspots: [
        {
          pitch: null,
          yaw: null,
          type: "info",
          heading: null,
          text: null,
          video: null,
          thumbnail: null,
        },
      ],

      clickedHotspot: [
        {
          id: null,
          frame: null,
          heading: null,
          text: null,
          x: null,
          y: null,
          video: null,
          thumbnail: null,
        },
      ],

      hotspotclicked: false,
      filteredResultArr: [],

      exteriorFeatures: true,
      interiorFeatures: false,
      colorIndex: 0,

      spotWrapper: false,

      interiorMaster: false,

      vehicleData: {
        grades: null,
        grade_data: {},
        model: null,
        category: null,
        categoryImageCount: null,
        interiorData: {},
      },
      dataChanged: null,
      setSpotCurrentImage: 0,
      widgetActive: false,
      hotSpotActiveId: "",
      colorsTray: true,
      exImgexcist: null,
      imageExteriorColourList: {},
      imageInteriorColourList: {},

      selectedGrade: "",
      selectedGradeCategories: [],
      selectedGradeExteriorFeatures: [],
      isBookNowModalVisible: false,

      isLoggedIn: false,
    };
  },
  watch: {
    services() {
      this.forceReload();
    },
  },
  computed: {
    // displayedCategories() {
    //   return this.isArabicLang ? this.arabicCategories : this.selectedGradeCategories;
    // }
  },


  beforeMount: function(){
   
    // get the current url
    const  currentUrl = window.location.pathname;

    const country = currentUrl.split("/")[1];
    const language =  currentUrl.split("/")[2];

    // check if the country is in localstorage
    if(localStorage.getItem("country") == undefined || localStorage.getItem("country") ==""){
      localStorage.setItem("country", country)
    }

    if(localStorage.getItem("language") == undefined || localStorage.getItem("language") ==""){
      localStorage.setItem("language", language)
    }

  
  },  mounted: function () {
 

    
    this.checkLanguage();

    


    this.$nextTick(function () {

     

      const login = checkLogin();
      if (login) {
        this.isLoggedIn = login;
      } else {
        this.$router.push("/login");
      }

      if (this.selectedVehicleModelName) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

        this.isLoadingFeature2 = false;
        this.isLoadingFeature = false;


      }

      window.addEventListener("load", () => {
        this.windowWidth = window.innerWidth;
      });

      if (this.windowWidth < 991) {
        this.mobMode = true;
      }

      const targetDiv = this.$refs._360_;

      setTimeout(() => {
        if (this.imageCount > 20) this.divLoaded = true;
      }, 3000);

      this.getVehicles();
    });
    // this.postModelToSalesforce(this.selectedVehicleModelName)

    this.$el.addEventListener("click", this.onClick);
  },
  updated: function () {},
  created() {
    
    
  },
  computed: {},
  methods: {
    resizeCanvas() {
     
     
      
       
      
    },
    checkLanguage() {
      if (typeof localStorage !== 'undefined' && localStorage.getItem('language') === 'ar') {
        this.isArabicLang = true;
      }
    },

    setValue(valueDef) {
      this.isActiveColor = valueDef;
      this.forceReload();
    },

    forceReload() {
      this.carouselKey += 1;
    },


    toggleInteriorColourList() {
      if (this.InteriorColourListbox == true) {
        this.InteriorColourListbox = false;
        this.$refs.colorToggleBtn.classList.add("toggleArrow");
      } else {
        this.InteriorColourListbox = true;
        this.$refs.colorToggleBtn.classList.remove("toggleArrow");
      }
    },
    imageUpdated(status) {
      this.isImagesLoaded = status;
      this.gradeoption = false;
      //this.$refs.mainOptionRef.classList.add("");

      const canvas =  document.querySelector(".v360-image-container")
      const container = canvas.parentElement;

      // Adjust canvas to fit parent container's width and height
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;

      // Ensure the image fits without cropping
      canvas.style.width = '100%';
      canvas.style.height = 'auto';
    },
    updatedColorName(colorName) {
      return colorName.includes("|") ? colorName.split("|")[0] : colorName;
    },
    addBtntagging(event_happened, url, vehicle_name, vehicleid) {
      let event_126 = "";
      let events = "";
      let eVar14 = event_happened + "|" + url;

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
    },
    toggleExteriorColorBox() {
      if (this.colorsInExterior == true) {
        this.colorsInExterior = false;
      } else {
        this.colorsInExterior = true;
      }
    },

    getVehicles() {
      const modelName = this.$route.params.modelName;
      const country_code = localStorage.getItem("country")

      axios.get(this.$apibaseurl + country_code+"/vehicles").then((result) => {
        let modelIndex = 0;
        if (modelName !== null && modelName !== undefined) {
          modelIndex = result.data.data.findIndex(
            (model) => model.name.toLowerCase() === modelName.toLowerCase()
          );
          if (modelIndex === -1) {
            modelIndex = 0;
          }
        }

        this.vehicleModelList = result.data.data;

        this.selectedVehicleModelName = this.vehicleModelList[modelIndex].name;

        // if localstorage model is not null then set model to localstorage
        if (localStorage.getItem('name') !== null) {
          this.postModelToSalesforce(this.selectedVehicleModelName)
        }


        this.getVehicleModelData();
      });
    },

    onVehicleModelChange(event) {
      // this.$router.push({
      //   name: "vehicleModel",
      //   params: { modelName: event.target.value },
      // });
      this.gradeoption = true
      this.selectedVehicleModelName = event.target.value;
      this.getVehicleModelData(event.target.value);



      let event_happened = this.selectedVehicleModelName
      let vehicle_name = this.selectedvehicleModel; //this.modelName;
      let vehicleid = "";
      //let vehicleid = this.selectedVehicleId;
      let vehicle_catgory = this.categoryExInt;

      let event_126 = "";
      let events = "";

      let all =
        vehicle_name +
        "|" +
        vehicle_catgory +
        "|" +
        event_happened;

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all,
        ""
      );


    },

    toggleFeatureLeft() {
      // this.toggleLeftFeatureBox = !this.toggleLeftFeatureBox;
      // this.toggleRightFeatureBox = !this.toggleRightFeatureBox;
      // this.isActiveFeaturebox = !this.isActiveFeaturebox;
      this.$refs.sidePanel.classList.add("show");
    },

    closeSidePanel() {
      this.$refs.sidePanel.classList.remove("show");
      this.$refs.sidePanel.classList.add("hidePanel");
    },

    getIntRoute(cat, i) {
      if (i == 0) this.activeOptionId = i;

      if (cat == "exterior") {
        this.interiorMaster = false;
        this.colorsTray = true;
        this.exOptions = true;
        this.sm_toggleMenu = true;
      }
    },
    handleFocusOut() {
      // this.$refs.exToggleMenu.checked = false;
      // this.$refs.featuresOptionnRef.classList.remove("openPanel");
      // this.widgetActive = false;
      // this.$refs.InExWrapper.classList.remove("widgetHotActive");
      // this.$refs.Exee.classList.remove("zoomed");
    },
    onClick() {
      if ((this.divLoaded = true)) {
        this.divLoaded = false;
      }
    },
    openFeatures() {
      if (this.$refs.featuresOptionnRef.classList.contains("openPanel")) {
        this.$refs.featuresOptionnRef.classList.remove("openPanel");
      } else {
        this.$refs.featuresOptionnRef.classList.add("openPanel");
        //this.$refs.featuresOptionnRef.classList.remove("hidePanel");
      }
    },
    playVideoClick() {
      const video = document.getElementById("vehicleVideo");
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }

      const circlePlayButton = document.getElementById("circle-play-b");

      video.addEventListener("playing", function () {
        circlePlayButton.style.opacity = 0;
      });
      video.addEventListener("pause", function () {
        circlePlayButton.style.opacity = 1;
      });
    },
    hideSpotWrapper() {
      // this.hotSpotActiveId = '';
      this.widgetActive = false;
      this.$refs.Exee.classList.remove("zoomed");
      this.$refs.InExWrapper.classList.remove("widgetHotActive");
    },
    changeExteriorCarColour(colourCode, index, colorAr) {
      this.threeSixtyImagePath = null;
      console.log(colorAr,'onExtChange - Color code value')
      this.isActiveColor = index;

      if(this.isArabicLang){
        this.selectedExteriorColor = colorAr;
      }else{
        this.selectedExteriorColor = colourCode.replace(/-/g, " ");
      }


      let filteredImages = null;
      this.vehicleData.categoryImageCount =
      filteredImages = this.selectedGrade.categories[0].category_images.filter(element => element.colour_code == colourCode);

      let totalImageCount = 0;

      filteredImages.forEach(element => {
          if (element.image_path && Array.isArray(element.image_path)) {
              totalImageCount += element.image_path.length;
          }
      });

      // Assign the total image count to vehicleData.categoryImageCount
      this.vehicleData.categoryImageCount = totalImageCount;



      var vehicleImagePath =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return element.colour_code == colourCode;
        })[0].image_path;







      this.threeSixtyImagePath =  vehicleImagePath;
      this.imageCount = totalImageCount;



      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      this.colorName = colourCode.replace(/-/g, " ");



      let event_happened = this.colorName;
      let vehicle_name = this.selectedvehicleModel; //this.modelName;
      let vehicleid = this.selectedVehicleId;
      let vehicle_catgory = this.categoryExInt;

      let event_126 = "";
      let events = "";

      let all =
        vehicle_name +
        "|" +
        vehicle_catgory +
        "|" +

        this.selectedVehicleModelName +
        "|" +
        event_happened;

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all,
        ""
      );
    },
    changeInteriorCarColour(colourCode, index, colorAr) {
      this.isActiveInteriorColor = index;

      if(this.isArabicLang){
        this.interiorCategoryItemData.forEach(function(element) {
          if ( colourCode === element.colour_code || colourCode === element.colour_code_ar) {
            this.copyColorTrimmed = element.colour_code_ar;
            this.selectedInteriorColorParam = this.copyColorTrimmed;
            return;
          }

        }, this);
      }else{
        this.selectedInteriorColor = colourCode;
        this.copyColorTrimmed = this.selectedInteriorColor;
      }



      this.colorIndex = index;
      this.selectedInteriorColorParam = colourCode;

      this.selectedInteriorColor = colourCode
        .replace(this.selectedVehicleModelName.toLowerCase(), " ")
        .replace(/-/g, " ");



      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      let event_happened = this.selectedInteriorColor;
      let vehicle_name = this.selectedvehicleModel;
      let vehicleid = this.selectedVehicleId;

      let event_126 = "";
      let events = "";

      let all =
        vehicle_name +
        "|Interior|" +
        this.selectedVehicleModelName +
        //this.adobeGradecode +
        "|" +
        event_happened;

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all,
        ""
      );
    },

     postModelToSalesforce(model){
      // set model to localstorage

      localStorage.setItem('model', model);
      // post model to salesforce
      const leadID = localStorage.getItem('leadID');
      let data = JSON.stringify([
        {
          "leadId": leadID,
          "Model": model
        }
      ]);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://showroom.nissan-middleeast.com/backend/public/api/v1/salesforce/update',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });


    },

    async getVehicleModelData(grade) {
      const country_code = localStorage.getItem("country")??"qa"

     this.selectedVehicleGradeData = this.vehicle.grades;


     if (typeof this.vehicle.grades !== 'undefined') {
        this.isImagesLoaded = false;
        let checkVehicle = this.vehicle.grades.find(
          (g) => g.grade == grade
        );

        this.selectedVehicleGradeDataFull = checkVehicle;
        this.selectedVehicleGradeName = checkVehicle.grade;
        this.selectedGrade = checkVehicle;

        

        var firstLoadedColor  = ''

        if(!this.isArabicLang){
          firstLoadedColor = checkVehicle.categories[0].category_image_colours[0].colour_code;
        }else{
          firstLoadedColor = checkVehicle.categories[0].category_image_colours[0].colour_code_ar;
        }

      

        

        if (this.selectedGrade?.categories?.[1]?.category_images) {
          
        this.interiorData = this.selectedGrade.categories[1];

        var firstInteriorLoadedColor = checkVehicle.categories[1]?.category_image_colours[0]?.colour_code;


        //this.selectedInteriorColorParam = firstInteriorLoadedColor;

        if(firstInteriorLoadedColor){
                this.selectedInteriorColorParam = firstInteriorLoadedColor;
              }

      
        
        this.selectedInteriorColor = firstInteriorLoadedColor
                .replace(this.selectedVehicleModelName.toLowerCase(), " ")
                .replace(/-/g, " ");
        this.copyColorTrimmed = this.selectedInteriorColor;
        }



        this.isActiveColor = 0;
        this.selectedExteriorColor = firstLoadedColor.replace(/-/g, " ");


        var vehicleImagePath = checkVehicle.categories[0].category_images.filter((element) => {

          if(!this.isArabicLang){
            return element.colour_code == firstLoadedColor;
          }else
          return element.colour_code_ar == firstLoadedColor;
         
        })[0].image_path;




        this.imageExteriorColourList = checkVehicle.categories[0].category_image_colours;

        this.threeSixtyImagePath =  vehicleImagePath;
        this.dataChanged++;
        // this.imageCount = this.selectedVehicleGradeDataFull.categoryImageCount;
        this.isImagesLoaded = true;

     } else {

      

        const foundVehicle = this.vehicleModelList.find(
          (vehicle) => vehicle.name === this.selectedVehicleModelName
        );

        if (foundVehicle) {
          this.selectedVehicleId = foundVehicle.code;

          this.selectedCta_1 = foundVehicle.cta_1;

          this.selectedCta_1_Link = foundVehicle.cta_1_url;

            if(this.isArabicLang){
              if(this.selectedCta_1_Link !== null){
                this.selectedCta_1_Link = this.selectedCta_1_Link.replace('en.', 'ar.');
              }
            }else{
             this.selectedCta_1_Link = foundVehicle.cta_1_url;
            }




          this.selectedCta_2 = foundVehicle.cta_2;
          this.selectedCta_2_Link = foundVehicle.cta_2_url;

          if(this.isArabicLang){
              if(this.selectedCta_2_Link !== null){
                this.selectedCta_2_Link = this.selectedCta_2_Link.replace('/en', '/ar');
              }
            }else{
             this.selectedCta_2_Link = foundVehicle.cta_2_url;
            }


        } else {
          this.selectedVehicleId = "";
          this.selectedCta_1 = "";
          this.selectedCta_1_Link = "#";
          this.selectedCta_2 = "";
          this.selectedCta_2_Link = "#";
        }

      this.isImagesLoaded = false;

        await axios
          .get(this.$apibaseurl + country_code+"/vehicles/" + this.selectedVehicleModelName)
          .then((result) => {
            this.vehicle = result.data;

            //console.log(this.vehicle,"this.vehicle--------");

           this.selectedVehicleEn =  this.vehicle.vehicle_model.name
           this.selectedVehicleAr =  this.vehicle.vehicle_model.name_ar

            this.dataChanged = Math.floor(Math.random() * 10) + 1;
            this.vehicleData.grades = result.data.grades;

            if (this.vehicleData.grades[0] !== undefined && this.dataChanged) {
              var firstLoadedColor =
                this.vehicleData.grades[0].categories[0].category_image_colours[0]
                  .colour_code;



                  var firstLoadedColorAR =
                this.vehicleData.grades[0].categories[0].category_image_colours[0]
                  .colour_code_ar;

                
              if( this.vehicleData.grades[0]?.categories[1]?.category_image_colours){
                
              var firstInteriorLoadedColor =
                this.vehicleData.grades[0]?.categories[1]?.category_image_colours[0]
                  ?.colour_code;

              }

              //     var firstInteriorLoadedColorAR =
              //   this.vehicleData.grades[0].categories[1].category_images[0]
              //     .colour_code_ar;




              this.getGradeData(
                this.vehicleData.grades[0].grade_code,
                0,
                firstLoadedColor,
                0
              );

              if(this.isArabicLang){
                
                this.selectedExteriorColor = firstLoadedColorAR.replace(/-/g, " ");
              }else
              this.selectedExteriorColor = firstLoadedColor.replace(/-/g, " ");


              if(firstInteriorLoadedColor){
                this.selectedInteriorColorParam = firstInteriorLoadedColor;
              }


              // this.selectedInteriorColor = firstInteriorLoadedColor
              //   .replace(this.selectedVehicleModelName.toLowerCase(), " ")
              //   .replace(/-/g, " ");



              // this.copyColorTrimmed = this.selectedInteriorColor;


            //   if(this.isArabicLang){
            //     this.selectedInteriorColorParam = firstInteriorLoadedColor;
            // this.selectedInteriorColor = firstInteriorLoadedColorAR
            // this.copyColorTrimmed = this.selectedInteriorColor;
            // }else{
            // this.selectedInteriorColor = firstInteriorLoadedColor
            //   .replace(this.selectedVehicleModelName.toLowerCase(), " ")
            //   .replace(/-/g, " ");
            //   this.selectedInteriorColorParam = firstInteriorLoadedColor;
            //   this.copyColorTrimmed = this.selectedInteriorColor;
            // }




              this.threesixtyStatus = true;
              this.vehicleFoundStatus = false;


            } else {
              this.vehicleFoundStatus = true;
              this.threesixtyStatus = false;
            }

            this.isActiveColor = 0;
          });

        this.selectedvehicleModel = this.selectedVehicleModelName;
        let event_happened = this.selectedvehicleModel;
        let vehicle_name = this.selectedvehicleModel;
        let vehicleid = this.selectedVehicleId;
        let event_126 = "";
        let events = ""; //'event26';

        let all = vehicle_name + "|" + this.adobeGradecode;

        adobeAnalytics(
          "R",
          vehicle_name,
          "",
          vehicle_name,
          vehicleid,
          "EN",
          event_happened,
          "",
          "",
          "",
          event_126,
          events,
          "",
          all,
          ""
        );
        store.commit("selectedCar", this.selectedvehicleModel);
        store.commit("selectedCarVehicleId", this.selectedVehicleId);
      }

    },
    getGradeData(gradeCode, id, color, extOrInt) {

      this.adobeGradecode = gradeCode;

      this.gradeWalkId = id;
      this.openHotSpotFormImgFeatureId = 0;

      // const defColor = this.vehicleData.grades[0]
      // console.log(defColor,"defColor");

      this.colorName = color;

      /**
       * When Grade is changing, that time, you need to change these below datas:
       * change Categories.
       * change Images
       * change Colours
       * change Features
       */

      //removing active color class when change grade

      //this.$refs.CarouselColor.setValue(0);

      //this.selectedVehicleModelName = this.vehicleModelList[0].name;

      const selectedGrade = this.vehicleData.grades.filter((value) => {
        return value.grade_code == gradeCode;
      });
      //console.log(selectedGrade, '1 selected Grade');
      this.selectedGrade = selectedGrade[0];

      this.categoryExInt = this.selectedGrade.categories[extOrInt].name;

      // Lets change the Categories
      this.selectedGradeCategories = [];
      this.selectedGrade.categories.map((cateItem) => {
        this.selectedGradeCategories.push(cateItem.name);
      });
      // -- Lets change the Categories

      this.imageExteriorColourList =
        this.selectedGrade.categories[0].category_image_colours;


      this.imageInteriorColourList =
        this.selectedGrade.categories[1].category_image_colours;

        this.interiorCategoryItemData =
        this.selectedGrade.categories[1].category_images;

      this.vehicleData.categoryImageCount =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return (
            element.colour_code == this.imageExteriorColourList[0].colour_code
          );
        });

        let totalImageCount = 0;



        this.selectedGrade.categories[0].category_images.forEach(element => {
            if (element.image_path && Array.isArray(element.image_path)) {
                totalImageCount += element.image_path.length;
            }
        });

        // Assign the total image count to vehicleData.categoryImageCount





      var vehicleImagePath =
        this.selectedGrade.categories[0].category_images.filter((element) => {
          return (
            element.colour_code == this.imageExteriorColourList[0].colour_code
          );
        })[0].image_path;

      this.threeSixtyImagePath =  vehicleImagePath;

      this.imageCount = totalImageCount;
      //this.imageCount = this.vehicleData.categoryImageCount;

      // var exteriorCategoryFeatures = this.selectedGrade.categories[0].category_image_features;

      // this.selectedGradeExteriorFeatures = exteriorCategoryFeatures;
      // // console.log(exteriorCategoryFeatures, 'exteriorCategoryFeatures')

      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      // this.exteriorHotspots = [];
      // exteriorCategoryFeatures.map((item) => {
      //   this.exteriorHotspots.push({
      //     id: item.id,
      //     frame: item.category_image_no,
      //     heading: item.heading,
      //     text: item.description,
      //     x: item.spot_x,
      //     y: item.spot_y,
      //     video: item.video,
      //     thumbnail: item.video_poster,
      //   });
      // });

      // // -- Exterior Data

      // // Start - Interior Feature
      this.interiorData = this.selectedGrade.categories[1];

      // var interiorCategoryFeatures = this.selectedGrade.categories[1].category_image_features;
      // this.interiorHotspots = [];
      // interiorCategoryFeatures.map((item) => {
      //   this.interiorHotspots.push({
      //     id: item.id,
      //     pitch: item.spot_x,
      //     yaw: item.spot_y,
      //     type: "info",
      //     text: item.heading,
      //     heading: item.heading,
      //     description: item.description,
      //     video: this.$apibaseurl + item.video,
      //     thumbnail: this.$apibaseurl + item.video_poster,
      //     clickHandlerFunc: this.hotSpotModalFunc,
      //     clickHandlerArgs: item.id,
      //   });
      // });
      // // End - Interior Feature

      // console.log(this.interiorHotspots, "Interior Hotspot");
    },
    hotSpotModalFunc(data, id) {
      this.$emit("hotspotClickOpenModal", id);
    },
    getCategory(type, id) {
      this.activeOptionId = id;
      let event_happened;
      if (type === "interior") {
        if (this.dataChanged) {
          this.interiorMaster = true;
          this.imageInteriorColourList = this.selectedGrade.categories[1].category_image_colours;
        }
        //this.exOptions = false;
        event_happened = this.selectedInteriorColor;
      } else {
        this.interiorMaster = false;
        event_happened = this.colorName;
      }

      let vehicle_name = this.selectedvehicleModel;
      let vehicleid = this.selectedVehicleId;

      let event_126 = "";
      let events = "";

      let all =
        vehicle_name +
        "|" + type +
        "|" + this.selectedVehicleModelName +
        //"|" + this.adobeGradecode +
        "|" + event_happened;

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all,
        ""
      );
    },
    getFeatures(featureData, id) {
      this.playOnce = false;
      this.activeOptionId = id;

      this.activeFeatureId = id;
      this.setSpotCurrentImage = parseInt(featureData.category_image_no);

      // console.log('ex hotspot feature id landing',featureData.id);
      this.openHotSpotFormImgFeatureId = parseInt(featureData.id);

      this.dataChanged = featureData.category_image_no;

      this.hotSpotActiveId = featureData.id;

      this.isWidgetHotActive = false;

      this.$refs.InExWrapper.classList.remove("widgetHotActive");

      // this.widgetActive = true
      if ((this.widgetActive = true)) {
        this.widgetActive = !this.widgetActive;
      }
      this.hotspotclicked = false;

      // if (
      //   featureData &&
      //   this.$refs.featuresOptionnRef.classList.contains("openPanel")
      // ) {
      //   this.$refs.exToggleMenu.checked = false;
      //   this.$refs.featuresOptionnRef.classList.remove("openPanel");
      // }
    },
    showWidget() {
      this.widgetActive = true;
    },
    hideWidget() {
      this.widgetActive = false;
    },
    fetchExteriorHot(id) {
      this.$refs.InExWrapper.classList.add("widgetHotActive");

      // this.hotSpotActiveId = id;
      this.clickedHotspot = this.exteriorHotspots.filter((e) => {
        e.id == id;

        return this.clickedHotspot;
      });
      this.hotspotclicked = true;

      const checkId = id;

      // console.log(this.clickedHotspot,"this.clickedHotspot");

      const filteredResult = this.clickedHotspot.find((e) => e.id == checkId);

      this.filteredResultArr = [];

      this.filteredResultArr.push(filteredResult);

      //console.log(this.filteredResultArr,"filteredResult");

      this.widgetActive = true;

      if ((this.widgetActive = true)) {
        this.$refs.Exee.classList.add("zoomed");
        this.isWidgetHotActive = true;

        if (this.windowWidth < 667) {
          setTimeout(() => {
            // this.$refs.mainOptionRef.classList.add("z-index_0");
            // this.$refs.featuresOptionnRef.classList.add("z-index_0");
            this.$refs.spotWrapper.classList.add("mobileSpot");
            this.$refs.sidePanel.classList.remove("show");
          }, 0);
        }
      }
      // else{
      //           this.$refs.mainOptionRef.classList.remove("z-index_0");
      //           this.$refs.featuresOptionnRef.classList.remove("z-index_0");
      //         }
    },

    CloseportraitPopup() {
      this.mobMode = false;
    },
    showModal() {
      let event_happened = "book a test drive";
      let vehicle_name = this.selectedvehicleModel;
      let vehicleid = this.selectedVehicleId;

      let event_126 = "";
      let events = "";

      let all = vehicle_name + "| Exterior |" + this.adobeGradecode;

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all,
        ""
      );
      this.isBookNowModalVisible = true;
    },
    // hideModal() {
    //   this.isBookNowModalVisible = false;
    // },
  },


};
</script>

<style>
.widget-label {
  margin-left: 15px;
  font-size: 40px;
}

.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.colorBoxInterior {
  z-index: 1;
  background: none !important;
}

.areaheaderName {
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  white-space: nowrap;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.larger-font {
  font-size: 7.5em;
  padding-top: 125px;
}

.home-button {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 10px;
  /* top: 10px; */
  bottom: 151px;
  z-index: 4;
}

.top-left-image {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 101px;
  /* height: 100px; */
}

.btnModal {
  font-size: 20px;
  padding: 11px;
  color: #fff;
  z-index: 222;
  position: absolute;
  top: 5px;
  right: 11px;
}

.area404 {
  text-align: center;
  margin-top: 10%;
}

.area404 h1 {
  font-size: 70px;
  color: #fff;
}

.area404 .title {
  font-size: 26px;
  color: #fff;
  font-weight: 400;
  margin-top: 26px;
}

.d-none {
  display: none;
}

.tooltip {
  position: absolute;
}

.v360-viewport {
  background-color: transparent;
  position: relative;
}
.v360-viewport canvas {
  width: 100% !important;  /* Fit the canvas to the full width of the container */
  height: auto !important; /* Keep aspect ratio */
  transform: scale(1) !important; /* Ensure no zoom */
  object-fit: contain;      /* Prevent any overflow or cutting */
  }
  

.v360-image-container {
  max-width: none !important;
  aspect-ratio: 0;
  display: block;
  overflow: visible;
  overflow-clip-margin: unset;
}

#v360-menu-btns {
  display: none;
}

#v360-menu-btns {
  display: none;
}

.feature-right-box {
  right: 31px;
}

/* .closeIcon {
  position: absolute;
  right: 5%;
  top: 0;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
} */
.hideBurger {
  display: none !important;
}

@media (max-width: 667px) {
  .closeIcon {
    font-size: 25px;
  }
}

.widgetHotActive .spotWrapper .descWrap {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.widgetHotActive .spotWrapper .descWrap .desc {
  margin-top: 0.55em;
}

/* @media (max-height: 768px) {
  .panIcon {
    height: 41%;
  }
} */
.ar-lang .ctaWrap{
  flex-direction: row-reverse;
}

</style>
