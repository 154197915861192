export const getGradeData = (grade, extOrInt, colorCode, walkId, openHotSpotFormImgFeatureId) => {
    
    const returnData = {};

    var firstLoadedColor = grade.categories[extOrInt].category_image_colours[0].colour_code.replace(/-/g, " ");
    returnData.colorName = firstLoadedColor;
    returnData.gradeWalkId = walkId;
    returnData.openHotSpotFormImgFeatureId = openHotSpotFormImgFeatureId;

    returnData.adobeGradecode = grade.grade_code;
    returnData.categoryExInt = grade.categories[extOrInt].name;

    returnData.imageColourList = grade.categories[extOrInt].category_image_colours;

    returnData.imageCount = grade.categories[extOrInt].category_images.filter((element) => {
        return element.colour_code == returnData.imageColourList[colorCode].colour_code;
      }).length;
    
    var vehicleImagePath = grade.categories[extOrInt].category_images.filter((element) => {
        return element.colour_code == returnData.imageColourList[colorCode].colour_code;
    })[0].image_path;

    returnData.threeSixtyImagePath = vehicleImagePath;  
    if(extOrInt) {
        returnData.interiorData = grade.categories[1];
    } else {
        returnData.exteriorData = grade.categories[0];
    }

    return returnData;
    
}

export const checkLogin = () => {
    let isLoggedIn = false;
    const storedToken = localStorage.getItem('token');
      
    if (storedToken) {
      isLoggedIn = true;
      return isLoggedIn;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const encodedKey = "OTBiNWUzYzItMTFjMC00ZTA5LThhYjc="; // base64 encoded
    const apiKey = atob(encodedKey);  // "90b5e3c2-11c0-4e09-8ab7-d7664ab6ff56"

// Check if api_key has the specified value
    if (urlParams.get("api_key") === apiKey+"-d7664ab6ff56") {
      localStorage.setItem("token", "kjashdiuoy3w@asdkj");
        isLoggedIn = true;
        return isLoggedIn;
    } 
    return isLoggedIn;
}

export const logout = () => {
    localStorage.removeItem('token');
}